import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Highcharts from "highcharts";




const propTypes = {
    chartTitle: PropTypes.string,
    xAxisTitle: PropTypes.string,
    yAxisTitle: PropTypes.string,
    chartData: PropTypes.array.isRequired,
    onAxisClick: PropTypes.func,


}

const ChunkStatuses = {
    LOADED: "Loaded",
    FAILED: "Failed",
    INPROGRESS: "InProgress"
}

var ReactHighcharts = null;
class ColumnChart extends Component {


    constructor(props) {
        super(props);
        this.state = {
            modulesLoaded: ChunkStatuses.INPROGRESS
        }
    }
    componentDidMount() {        
        Promise.all([
            import('highcharts/modules/accessibility'), import('highcharts/modules/exporting'), import('highcharts-react-official')
        ]).then(([acc, exp, rehigh]) => {  
            require("highcharts/modules/exporting")(Highcharts);
            require('highcharts/modules/accessibility')(Highcharts);          
            ReactHighcharts = rehigh.default;
            this.setState({ modulesLoaded: ChunkStatuses.LOADED });
        }).catch(() => {
            console.log("failed to load")
            this.setState({ modulesLoaded: ChunkStatuses.FAILED });
        })
    }

    render() {
        return <React.Fragment>
            {this.state.modulesLoaded  === ChunkStatuses.INPROGRESS ? <div></div> : <ReactHighcharts id="orderBarChat" options={this.getColumnChatOptions() } highcharts={Highcharts} containerProps = {{ style: {height: '400px'} }}></ReactHighcharts>}
        </React.Fragment>
    }

    getColumnChatOptions() {
        return {
            credits: {
                enabled: false
            },
            chart: {
                renderTo: 'container',
                type: 'column',
                // options3d: {
                //     enabled: true,
                //     alpha: 5,
                //     beta: 2,
                //     depth: 52,
                //     viewDistance: 25
                // }
            },
            accessibility: {
                description: this.props.chartTitle ? this.props.chartTitle : '',
                point: {
                    valueDescriptionFormat: '{index}. {point.series.xAxis.options.title.text}, {xDescription}, {point.series.yAxis.options.title.text}: {value}.'
                  }
            },
            title: {
                text: this.props.chartTitle ? this.props.chartTitle : ''
            },
            xAxis: {
                type: 'category',
                title: {
                    text: this.props.xAxisTitle ? this.props.xAxisTitle : ''
                }
            },
            yAxis: {
                title: {
                    text: this.props.yAxisTitle ? this.props.yAxisTitle : ''
                },
            },
            legend: {
                enabled: false
            },
            plotOptions: {
                series: {
                    lineWidth: 2,
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y:.0f}'
                    },
                    events: {
                        click: this.onChartAxisClick.bind(this)
                    },
                    point: {
                        events: {
                            click: this.onChartAxisClick.bind(this)
                        }
                    },
                }
            },

            tooltip: {
                headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
                pointFormat: '<span>{point.name}</span>: <b>{point.y:.0f}</b><br/>'
            },
            series: [
                {
                    "name": "Value",
                    //"colorByPoint": true,
                    "data": this.props.chartData
                }
            ],


        };
    }

    onChartAxisClick(event) {
        this.props.onAxisClick && (event.point != undefined ? this.props.onAxisClick(event.point) : this.props.onAxisClick(event.target));
    }

}

ColumnChart.propTypes = propTypes;

export default ColumnChart;